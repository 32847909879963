<template>
  <div v-if="mobile" class="upload mobile">
    <section class="design-panel">
      <section class="design-elements">
        <section class="design-element">
          <section class="element">
          </section>
          <div class="divider"></div>
        </section>
      </section>
    </section>
    <section class="design-library">
      <!-- UPLOAD BUTTONS  -->
      <section class="library-btns">
        <div class="p-2 d-flex align-items-center justify-content-center flex-row" style="width: 50%; position: relative;" aria-label="Upload Image">
          <input @change="uploadFile(this, 'photo')" type="file" accept="image/jpg, image/jpeg, image/png" class="post-input" id="camera-input" capture>
          <label class="post-input-label" for="camera-input">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16">
              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
            </svg>
          </label>
        </div>
        <div class="p-2 d-flex align-items-center justify-content-center flex-row" style="width: 50%; position: relative;" aria-label="Upload Video">
          <input type="file" class="post-input" id="video-input" accept="video/mp4, video/webm" capture>
          <label class="post-input-label" for="video-input">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-camera-reels-fill" viewBox="0 0 16 16">
              <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              <path d="M9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
              <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7z"/>
            </svg>
          </label>
        </div>
        <div class="p-2 d-flex align-items-center justify-content-center flex-row" style="width: 50%; position: relative;" aria-label="Upload Audio">
          <input type="file" class="post-input" id="audio-input" accept="audio/mp3" capture>
          <label class="post-input-label" for="audio-input">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-music-note-list" viewBox="0 0 16 16">
              <path d="M12 13c0 1.105-1.12 2-2.5 2S7 14.105 7 13s1.12-2 2.5-2 2.5.895 2.5 2z"/>
              <path fill-rule="evenodd" d="M12 3v10h-1V3h1z"/>
              <path d="M11 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 16 2.22V4l-5 1V2.82z"/>
              <path fill-rule="evenodd" d="M0 11.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 .5 7H8a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 .5 3H8a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </label>
        </div>
      </section>
      <!-- UPLOADED CONTENT -->
      <section class="uploaded-content">
        <div class="new-upload">
          <input type="file"
            id="upload-content"
            class="upload-content-input"
            accept="image/png, image/jpeg, image/jpg, video/webm, video/mp4, audio/mp3">
            <label for="upload-content" class="upload-content-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16">
                <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-play-btn-fill" viewBox="0 0 16 16">
                <path d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
                <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
              </svg>
            </label>
        </div>
      </section>
    </section>
  </div>
  <div v-else class="upload desktop">
    <div v-if="ready" class="ready"></div>
    <section class="upload-inner">
      <div class="display-cover">
        <video id="video" autoplay></video>

        <div class="video-options" @change="updateConstraints">
          <select v-model="cameraOption" :name="device.label" :id="device.id" class="custom-select" v-for="device in deviceOptions" :key="device.id">
              <option :value="device.id"> {{ device.label }} </option>
          </select>
        </div>

        <img class="screenshot-image d-none" id="screenshotImg" alt="">
        <div v-if="recording" class="controls recording" id="controls">
          <button class="btn"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="red" class="bi bi-record-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            </svg>  
          </button>
        </div>
        <div v-else class="controls" id="controls">
          <button @click="startRecording" class="btn"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" class="bi bi-record-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            </svg>  
          </button>
        </div>
      </div> 
      <section v-if="type == 'post'" class="upload-btns post-options"></section>
    </section>
  </div> 
</template>

<script>
import { ref, onMounted } from "vue"

export default {
  props: ['type'],
  setup(props) {
    const type = ref(props.type)

    const deviceOptions = ref([])
    const ready = ref(true)
    
    let streamStarted = false;
    const cameraOption = ref('')

    const recording = ref(false)
    const stream = null

    const mobile = ref(screen.width <= 760 ? true : false)

    const constraints = {
      video: {
        width: {
          min: 1280,
          ideal: 1920,
          max: 2560,
        },
        height: {
          min: 720,
          ideal: 1080,
          max: 1440
        },
      }
    };

    const startVideoSession = async () => {
      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({
          video: true,
          facingMode: 'user'
        }).then(function (currentStream) {
          startStream(currentStream)
          stream = currentStream
        })

        await getCameraSelection()
      }
    }
      
    const getCameraSelection = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      deviceOptions.value = videoDevices.map(videoDevice => {
        return { id: videoDevice.deviceId, label: videoDevice.label }
      });

      cameraOption.value = deviceOptions.value[0].id
    };

    const startStream = (currentStream) => {
      const video = document.getElementById("video")

      video.srcObject = currentStream;
      streamStarted = true;
      ready.value = false
    };

    const updateConstraints = () => {
      const updatedConstraints = {
        ...constraints,
        deviceId: {
          exact: cameraOptions.value
        }
      };

      startStream(updatedConstraints);
    }

    const playVideo = () => {
      const play = document.getElementById("play"),
        pause = document.getElementById("pause"),
        screenshot = document.getElementById("screentshot")

      if (streamStarted) {
        video.play();
        play.classList.add('d-none');
        pause.classList.remove('d-none');
        return;
      }
  
      if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
        const updatedConstraints = {
          ...constraints,
          deviceId: {
            exact: cameraOptions.value
          }
        };
        startStream(updatedConstraints);
      }
    
      const pauseStream = () => {
        video.pause();
        play.classList.remove('d-none');
        pause.classList.add('d-none');
      };

      const doScreenshot = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0);
        screenshotImage.src = canvas.toDataURL('image/webp');
        screenshotImage.classList.remove('d-none');
      };

      pause.onclick = pauseStream;
      screenshot.onclick = doScreenshot;
    }

    const startRecording = () => {
      recording.value = true
    }

    const stopRecording = () => {
      recording.value = false
    }

    return { playVideo, updateConstraints, deviceOptions, cameraOption, 
      recording, startRecording, stopRecording, ready, mobile }
  }
}
</script>

<style scoped>
.upload {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 50;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.upload.mobile {
  flex-flow: column nowrap;
}

/* MOBILE */

/* DESIGN PANEL */

.design-panel {
  width: 100%;
  height: 55vh;
}

.light .design-panel {
  background: rgb(194, 194, 194);
}

/* DESIGN LIBRARY */

.design-library {
  flex: 1;
  background: whitesmoke;
  background-image: url('../assets/images/growergangbg.png');
  background-size: cover;
}

.design-library label {
  color: lightslategrey;
  display: flex;
  align-items: center;
}

/* LIBRARY/UPLOADED CONTENT BUTTONS & LABELS */

.library-btns {
  border-bottom: 1px solid darkgray;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: whitesmoke;
}

.post-input-label,
.upload-content-label {
  cursor: pointer;
}

.post-input-label {
  padding: 3px;
}

.post-input,
.upload-content-input {
  position: absolute; 
  right: 0; 
  opacity: 0; 
  margin: 0; 
  padding: 0; 
  border: none;
}

.upload-content-input {
  height: 100%;
  top: 0;
}

.upload-content-label {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 100%;
}

.upload-content-label svg {
  flex: 1;
}

/* UPLOADED CONTENT */

.uploaded-content {
  padding: 18px;
  position: relative;
  overflow: hidden;
  
  overflow-y: auto;
}

.new-upload {
  width: 30%;
  height: 9rem;
  border: 1px solid transparent;
  border-radius: 8px;
  position: relative;
  background: rgba(221, 221, 221, 0.6);
}

.new-upload-svg {
  position: absolute;
  bottom: 1px;
  right: 1px;
}

/* DESKTOP */

.upload.desktop {
  align-items: center;
}

.light .upload {
  background-image: url('../assets/images/growergangbg.png');
  background-size: cover;
}

.dark .upload {
  background: rgb(19, 19, 19);
}

.upload-inner {
  position: relative;
  display: flex;
  align-items: flex-end;
  border: 1px solid darkgray;
  border-radius: 6px;
  overflow: hidden;
  z-index: 45;
  flex-flow: column nowrap;
  margin: 5px;
}

.light .upload-inner {
  background: darkgray;
}

/* DESKTOP UPLOAD BUTTONS */

.upload-btns {
  height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.upload-btn {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.upload-btn:first-child {
  border-right: 1px solid darkgrey;
}

.upload-btn svg {
  height: 30%;
}

.dark .upload-btns {
  background: rgb(19, 19, 19);
}

.dark .upload-btn  svg {
  fill: #DC1C64;
}

.light .upload-btns {
  background: lightgray;
}

.light .upload-btn  svg {
  fill: rgb(19, 19, 19);
}

.light .upload-btn:hover svg {
  fill: darkgray;
}

.ready {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 100;
  background: white;
}


/* DESKTOP VIDEO */

.display-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

#video {
  background: rgba(0, 0, 0, 0.2);
  max-width: 100%;
  height: auto;
}

.video-options {
  position: absolute;
  left: 20px;
  top: 30px;
}

.screenshot-image {
  width: 150px;
  height: 90px;
  border-radius: 4px;
  border: 2px solid whitesmoke;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 5px;
  left: 10px;
  background: white;
}

/* DESKTOP VIDEO CONTROLS */

.controls {
  position: absolute;
  right: 1%;
  top: 8%;
  display: flex;
  flex-flow: column nowrap;
}

.controls > button {
  width: 5rem;
  height: 5rem;
  padding: 0;
  border: none;
  margin: 0 6px;
  background: transparent;
}

.controls > button:hover svg {
  fill: lightgreen;
}

/*  MEDIA QUERIES */

@media (min-width: 300px) and (max-width: 400px) {
  .controls {
    flex-direction: column;
  }

  .controls button {
    margin: 5px 0 !important;
  }
}

/* MOBILE */

@media only screen and (max-width: 768px) {
  .upload-inner {
    aspect-ratio: 9 / 16;
  }
}
</style>